@import url(https://fonts.googleapis.com/css2?family=Cabin&family=Roboto+Condensed&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;

  background-color: #d3a008f7;

  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  font-family: "Cabin", sans-serif;
}

.navLink {
  color: #cacaca;
  font-size: 1rem;
  margin-top: 3px;
}

.Navbar {
  margin: 0rem 0rem 1rem 0rem;
  background-color: #cacaca;
}

.dropdown {
  width: 100%;
  /* padding: 0px 70%; */
  display: flex;
  justify-content: flex-end;
}
.App button {
  border-radius: 12px;
  background-color: #343434;
  color: #fcfaf1;
  border: 2px solid #cacaca;
  font-size: 28px;
  font-family: "Roboto Condensed", sans-serif;
}
.App button:hover {
  color: #e6b31e;
  border: 2px solid #e6b31e;
}

/* .logo {
  height: 20%;
  width: 60%;
  padding-top: 20px;
} */

#mainLogo {
  /* background-color: red;  */
  height: 20%;
  width: 60%;
  padding-top: 20px;
}


#navlogo {
  /* background-color: blue; */
  height: auto;
  width: 25%;
  /* background-color: white;
  border-radius: 5px;
  max-width: 100px; */
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #343434;
  padding: 1rem;
  border-radius: 10px;
  border: 4px #ba9503 solid;
  border: 4px #ffffff solid;
  width: 100%;
  /* max-height: 40%vw; */
}

.sign-up {
  color: #fcfaf1;
  padding: 1rem;
  background-color: #343434;

}
/* Residual animation class that didn't work but that I don't want to delete till i'm sure */
/* .form {
  opacity: .5;
} */

.label {
  color: #cacaca;
  padding: 0.5rem;
  font-size: calc(3px + 2vmin);
  width: 100%;
}

input {
  text-align: center;
  width: 100%;
  outline: none;
  border-style: hidden;
}

.visible {
  visibility: null;
}

.hidden {
  visibility: hidden;
}

.footer {
  font-size: calc(1px + 2vmin);
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #343434;
  color: #fcfaf1;
  font-family: "Roboto Condensed", sans-serif;
  padding: 1% 0;
}
/* .header {
  background-color: #fcfaf1;
} */

#typeLabel {
  padding-top: 5%;
  /* background-color: red; */
}

/* Checkbox */
.termsContainer {
  /* padding: .5rem; */
  font-size: calc(3px + 2vmin);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* background-color: yellow; */
}

.checkboxLabel {
  /* padding: 0px 50px; */
  /* background-color: lightblue; */
  /* vertical-align: middle; */
  display: block;
  float: left;
  padding: 5% 15% 0% 0%;
  white-space: nowrap;
}

span {
  vertical-align: middle;
}

.checkboxInput {
  vertical-align: middle;
  padding: 10px;
  border: 3px solid red;
  background-color: green;

  /* width: 15px;
  height: 15px;
  padding: 50px;
  margin: 0;
  vertical-align: bottom;
  position: relative;
  top: -1px;
  background-color: blue; */
}

/* input[type=checkbox], input[type=radio] {
  vertical-align: middle;
  position: relative;
  bottom: 1px;
}
input[type=radio] {
  bottom: 2px;
} */

.button {
  width: 100%;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.rerouteContainer {
  /* align-self: flex-end; */
  width: 100%;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.rerouteText {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  /* background-color: blue; */
  font-size: 0.8rem;
}

.overviewContainer {
  width: 100%;
  max-height: 600px;
  align-items: center;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.overview {
  max-width: 80%;
  min-width: 50%;

  /* min-height: 2rem;
  max-height: 60rem; */
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e6b31e;
  padding: 1rem;
  border-radius: 10px;
  border: 2px rgb(235, 234, 190) solid;
}
.App .signUp {
  max-width: 37%;
}

